body {
    padding-top: 51px;
    background: #fefefe;
    color: #6c6a6c;
    font-family: 'Josefin Sans', sans-serif;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.site-header {
    margin-top: 50px;
    margin-bottom: 50px;
    background-color: transparent;
    color: pink;
    line-height: 1;
    text-decoration: none;
    cursor: pointer;
    -webkit-transition: color .2s ease-out;
    transition: color .2s ease-out;

    @media (max-width: 600px) {
        display: none;
    }

    &:hover {
        text-decoration: none;
    }

    .site-title {
        display: block;
        font-family: 'Dancing Script', cursive;
        font-size: 130px;
    }


    .site-tagline {
        display: block;
        font-family: 'Dancing Script', cursive;
        font-size: 30px;
    }
}

.main-content {
    font-size: 18px;

    .image-preview {
        width: 100%;
        margin-bottom: 30px;
    }
}

a {
    color: #a0a1a3;
    text-decoration: none;
    cursor: pointer;
    -webkit-transition: color .2s ease-out;
    transition: color .2s ease-out;

    &:hover {
        color: #6c6a6c;
        text-decoration: none;
    }
}

.bottom {
    position: absolute;
    bottom: 0;
}

article.main-content {

    /* Fancy numbered lists */
    ol {
        padding: 10px 20px;
        list-style-type: none;
    }
    ol li {
        counter-increment: step-counter;
        margin-bottom: 20px;
        margin-left: 10px;
    }
    ol li:last-of-type {
        margin-bottom: 0;
    }
    ol li::before {
        position: absolute;
        content: counter(step-counter);
        margin-left: -44px;
        padding: 4px 13px;
        font-family: Verdana, Geneva, sans-serif;
        font-weight: bold;
        background-color: pink;
        color: white;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
    }
    ol li p {
        display: inline-block;
        margin-left: 5px;
        width: 100%;
    }
}

/* Fix for Pop Up script by mypopups.com */
.mypopups-modal {
    top: 50% !important;
}
