@charset "utf-8";


// Variables to modify font folders
$icon-font-path:      "../fonts/bootstrap/";
$fa-font-path:        "../fonts/font-awesome/";


// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "bootstrap/bootstrap",
        "font-awesome/font-awesome",
        "custom"
;
